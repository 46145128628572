.table-mobile {
    // Vars

    // Support

    // Module
    & {
        .table-mobile--entry {
            font-size: $fz--small;
            padding: 19px 0;
            border-bottom: solid 1px $c--monochrome-light-gray;

            &:first-child {
                border-top: solid 1px $c--monochrome-light-gray;
            }
        }

        .table-mobile--cell {
            display: flex;
        }

        .table-mobile--label {
            margin-bottom: 16px;

            @include ff--base-bold;
        }

        .table-mobile--popup {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 5px;
        }
    }

    // Facets

    // States
}
