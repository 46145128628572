.casestudy-header {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .casestudy-header--container.casestudy-header--container {
            position: relative;
            overflow: visible;
            height: 100%;
        }

        .casestudy-header--row {
            justify-content: flex-end;
            height: 100%;

            @include mq('<=md') {
                justify-content: center;
            }
        }

        .casestudy-header--background {
            height: 400px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @include mq('<=sm') {
                position: relative;
            }
        }

        .casestudy-header--phone {
            z-index: z-index('casestudy-header');
            margin-top: 36px;
            position: absolute;
            bottom: 0;
            right: 18px;
            transform: translate3d(0, 50%, 0);

            @include mq('<=md') {
                right: unset;
                transform: translate3d(-50%, 50%, 0);
            }

            @include mq('<=sm') {
                position: relative;
                transform: unset;
            }
        }
    }

    // Facets

    // States
}
