// stylelint-disable selector-max-universal, plugin/selector-bem-pattern

.chatlio-widget {
    & {
        .chatlio-title-bar-button.chatlio-title-bar-button.chatlio-title-bar-button {
            margin-right: 30px;
            margin-bottom: 30px;
        }
    }
}
