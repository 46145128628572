@mixin animated-backgroundcolor-change($active-color, $inactive-color) {
    background: linear-gradient(70deg, $active-color, $active-color 50%, $inactive-color 10%);
    background-size: 300% 100%;
    transition: background-position 400ms ease-in-out;
}

@mixin animated-backgroundcolor-active($isActive) {
    @if ($isActive) {
        background-position: 0 0;
    } @else {
        background-position: 100% 0;
    }
}
