.product-example {
    // Vars

    // Support

    // Module
    & {
        & {
            display: block;
        }

        .product-example--subtitle {
            margin-bottom: 16px;
            color: $c--monochrome-dark-gray;
            font-weight: 300;

            @include type--subtitle;
        }

        .product-example--image {
            margin-left: auto;
            margin-right: auto;
            box-shadow: 0 0 1px $c--monochrome-light-gray;
            width: 372px;
            height: 210px;

            @include mq('<=md') {
                width: 456px;
                height: 285px;
            }

            @include mq('<=sm') {
                width: 327px;
                height: 210px;
            }
        }
    }

    // Facets

    // States
}
