.team-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background-color: $c--monochrome-pale-gray;

            @include mq('<=md') {
                padding: 60px 0;
            }

            @include dot-grid();
        }

        .team-list--row {
            justify-content: center;
            margin-bottom: 30px;

            @include mq('<=md') {
                margin-bottom: 70px;
            }

            @include mq('<=sm') {
                margin-bottom: 6px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .team-list--title-col {
            text-align: center;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .team-list--title {
            margin-bottom: 20px;
        }

        .team-list--member-col {
            margin-top: 30px;
            margin-bottom: 30px;

            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
