/// Primary
///
@mixin btn--primary() {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--primary-base();

            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--primary-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--primary-disabled();
        }
    }
}

/// Base
///
@mixin btn--primary-base() {
    color: $c--monochrome-white;
    background-color: $c--brand-primary;
    border-width: 0;
    border-color: transparent;
}

/// Focus
///
@mixin btn--primary-focus() {
    color: $c--monochrome-white;
    background-color: $c--brand-light-orange;
}

/// Active
///
@mixin btn--primary-active() {
    color: $c--monochrome-white;
    background-color: $c--brand-dark-orange;
}

/// Disabled
///
@mixin btn--primary-disabled() {
    color: $c--monochrome-silver;
    background-color: $c--monochrome-light-gray;
}
