// NOTE: We need to establish our own classes to disable the cms.base.css stylesheet from djangoCMS
.cms-ready {
    // Vars

    // Support

    // Module
    & {
        /// Removes the 5px margin-top value when logged into djangoCMS
        margin-top: 0 !important; // stylelint-disable-line declaration-no-important

        .cms-plugins-skeleton {
            @include hide-visually;
        }

        .cms-placeholder {
            overflow: auto;
            height: auto;
        }

        .cms-plugin {
            display: block;
        }

        // stylelint-disable
        [data-cms-plugin-edit-helper] {
            & {
                &::after {
                    content: '?';
                    z-index: 1000;

                    position: absolute;
                    top: 0;
                    left: 0;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 12px;
                    height: 12px;
                    padding-top: 1px;

                    background-color: rgba(black, 0.8);
                    color: white;
                    white-space: nowrap;

                    font-size: 0.8rem;
                    line-height: 0.8;

                    cursor: pointer;
                }
            }

            &:hover {
                &::after {
                    content: 'cms - edit: ' attr(data-cms-plugin-name);
                    width: auto;
                    height: auto;
                    padding: 1px 7px 1px;

                    font-size: $fz--x-small;
                }
            }
        }
        // stylelint-enable
    }

    // Facets

    // States
}
