/// Monochrome special decoration colors
///
$monochromes: (
    'white': #ffffff,
    'black': #000000,
    'pale-gray': #f5f6f8,
    'silver': #e6e7e9,
    'light-gray': #cccdcf,
    'gray': #8c8d8f,
    'dark-gray': #313234,
    'white-gray': #eaeaea,
);

/// Brand specific colors
///
$brands: (
    'primary': #ff9602,
    'secondary': #820028,
    'red': #d90947,
    'dark-orange': #eb6f00,
    'light-orange': #ffbd00,
    'very-light-orange': #fffbf2,
    'blue': #0286c6,
);

$gradient: (
    'orange': #ffae08,
    'red': #e30247,
    'blue': #3a87ad
);

/// Single purpose colors
///
$labels: (
    'text': #313234,
    'link': #ff9602,
    'link-hover': #eb6f00,
    'selection': #ff9602,
);

/// Signal colors
///
$signals: (
    //'info': #0286c6,
    //'warning': #eb6f00,
    'error': #e1114e,
    'success': #00b37d,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
    gradient: $gradient,
);
