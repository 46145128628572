.phone-frame {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-block;
            width: 270px;
            height: 543px;
            padding: 50px 10px 52px 11px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url('#{$web-static}/img/img_iphone.png');
        }

        .phone-frame--content {
            width: 248px;
            height: 440px;
            background-color: $c--monochrome-white;
            overflow: hidden;
        }

        .phone-frame--image {
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
