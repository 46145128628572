.fancy-list {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 100px 0;

            @include mq('<=md') {
                margin: 60px 0;
            }
        }

        .fancy-list--col {
            width: 100%;

            @include grid--span((ratio: 10/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .fancy-list--divider {
            border-bottom: solid 1px $c--monochrome-silver;
        }

        .fancy-list--row {
            justify-content: center;
            margin-top: 45px;
            // padding-bottom: 20px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                .fancy-list--divider {
                    border-bottom: none;
                }
            }
        }
    }

    // Facets

    // States
}
