.form-fieldset {
    // Vars

    // Support

    // Module
    & {
        .form-fieldset--label {
            display: block;
            margin-bottom: 16px;
        }
    }

    // Facets

    // States
}
