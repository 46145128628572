.table-large {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
            margin-bottom: 4px;
        }

        .table-large--table-row {
            border-bottom: solid 1px $c--monochrome-light-gray;

            &:first-child {
                border-top: solid 1px $c--monochrome-light-gray;

                @include ff--base-bold;
            }
        }


        .table-large--table-cell__content {
            width: 100% / 12 * 4;

            @include mq('<=md') {
                width: 50%;
            }

            @include mq('<=sm') {
                width: 100%;
                padding-bottom: 19px;
            }
        }

        .table-large--cell-content {
            display: flex;
        }

        .table-large--table-cell {
            padding: 19px 0;
            font-size: $fz--small;
            line-height: 1.47;

            @include mq('<=sm') {
                display: block;
                padding: 19px 0 8px;
            }
        }

        .table-large--popup {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 18px;
        }
    }

    // Facets

    // States
}
