.footer-linkgroup {
    // Vars

    // Support

    // Module
    & {
        .footer-linkgroup--link {
            line-height: 1.47;
            font-size: $fz--small;

            @include has-focus(false) {
                color: $c--monochrome-white;
            }

            @include has-focus(true) {
                color: $c--brand-primary;
            }
        }

        .footer-linkgroup--item {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Facets

    // States
}
