.product-examples {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-pale-gray;
            padding: 100px 0;

            @include mq('<=md') {
                padding: 60px 0;
            }

            @include dot-grid();
        }

        .product-examples--row {
            justify-content: center;
        }

        .product-examples--item {
            @include grid--span((ratio: 4/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=md') {
                margin-bottom: 60px;
            }
        }
    }

    // Facets

    // States
}
