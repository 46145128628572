.main-navigation-item {
    // Vars

    // Support

    // Module
    & {
        .main-navigation-item--link {
            text-decoration: none;
        }
    }

    // Facets

    // States
}
