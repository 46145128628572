.mobile-nav-item {
    // Vars

    // Support

    // Module
    & {
        .mobile-nav-item--link {
            color: $c--monochrome-white;
        }

        .mobile-nav-item--list {
            margin-top: 36px;
        }

        .mobile-nav-item--list-child {
            padding: 0;
            margin: 0;

            &:first-child {
                .mobile-nav-item--link__child {
                    padding-top: 0;
                }
            }

            &:last-child {
                .mobile-nav-item--link__child {
                    padding-bottom: 0;
                }
            }
        }

        .mobile-nav-item--link__child {
            color: $c--brand-secondary;
            padding: 18px 24px;

            @include router-state-active() {
                color: $c--monochrome-dark-gray;
            }
        }

        .mobile-nav-item--item-label {
            line-height: 1.53;
        }

        .mobile-nav-item--item-icon {
            height: 26px;
            width: 26px;
            margin-right: 18px;
        }
    }

    // Facets

    &.mobile-nav-item__primary {
        .mobile-nav-item--link {
            color: $c--monochrome-white;
        }
    }

    &.mobile-nav-item__secondary {
        .mobile-nav-item--link {
            color: $c--brand-primary;
        }
    }

    // States
}
