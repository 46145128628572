.mobile-panel {
    // Vars

    // Support

    // Module
    & {
        & {
            display: none;
        }

        .mobile-panel--navigation-header {
            display: none;
        }

        .mobile-panel--content {
            overflow: auto;
            display: none;

            opacity: 0;
            background: $c--brand-primary;
            transition: all 200ms ease-in-out;
            padding: 40px 12px 60px;

            @include mq('<=sm') {
                padding: 0;
            }
        }

        .mobile-panel--container.mobile-panel--container {
            margin: 18px auto;
        }

        .mobile-panel--row {
            margin-bottom: 36px;

            &:first-child {
                margin-top: 18px;
            }
        }
    }

    // Facets

    &.mobile-panel__open {
        & {
            z-index: z-index('mobile-navigation-panel');
            position: fixed;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

        .mobile-panel--navigation-header {
            display: block;
        }

        .mobile-panel--content {
            display: block;
            opacity: 1;
            height: 100%;
        }
    }

    &.mobile-panel__primary {
        &.mobile-panel__main-nav {
            @include dot-grid();

            position: fixed;
        }
    }

    &.mobile-panel__secondary {
        .mobile-panel--content {
            background-color: $c--monochrome-white;
        }
    }

    // States
}
