.expandable-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 60px 0;
            width: 100%;
        }

        .expandable-list--header {
            text-align: center;
            width: 100%;
        }

        .expandable-list--title {
            margin-bottom: 24px;
        }

        .expandable-list--row {
            justify-content: center;
        }

        .expandable-list--header-col {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, my: '<=sm'));
        }

        .expandable-list--logo-item {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: 12px 0;

            @include grid--span((ratio: 2/12));
            @include grid--span((ratio: 4/12, mq: '<=sm'));
        }

        .expandable-list--logo-image {
            display: flex;
            border: solid 2px $c--monochrome-white-gray;
            border-radius: 16px;
            padding: 2px;
            margin-bottom: 6px;
        }

        .expandable-list--logo-image-img {
            border-radius: 12px;
        }

        .expandable-list--lead {
            text-align: center;
            margin-bottom: 60px;
            margin-left: auto;
            margin-right: auto;
            max-width: 780px;

            @include mq('<=md') {
                max-width: 456px;
                margin-bottom: 36px;
            }
        }

        .expandable-list--col {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include grid--span((ratio: 12/12));
        }

        .expandable-list--link-item {
            padding: 30px 0;
            text-align: center;

            @include grid--span((ratio: 2/12));
            @include grid--span((ratio: 4/12, mq: '<=sm'));
        }

        .expandable-list--link.expandable-list--link {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $c--monochrome-dark-gray;
            line-height: 1.47;
            font-size: $fz--small;

            @include has-focus(true) {
                color: $c--label-link-hover;
            }
        }

        .expandable-list--list {
            width: 100%;
        }

        .expandable-list--links {
            justify-content: center;
            margin-top: -18px;
        }

        .expandable-list--logos {
            margin-top: -12px;
            justify-content: center;
        }

        .expandable-list--logo-caption {
            text-align: center;
            font-size: $fz--x-small;
            line-height: 1.33;
        }

        .expandable-list--expand-wrapper {
            text-align: center;
            margin-top: 30px;

            @include mq('<=md') {
                margin-top: 18px;
            }
        }

        .expandable-list--expand-icon {
            display: inline-block;
            transform: rotateZ(90deg);
        }

        .expandable-list--footer-row {
            margin-top: 60px;
        }
    }

    // Facets
    &.expandable-list__links {
        .expandable-list--link-item {
            padding: 18px 0;

            @include grid--span((ratio: 3/12, mq: '<=md'));
            @include grid--span((ratio: 6/12, mq: '<=sm'));
        }
    }

    // States
}
