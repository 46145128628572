
.rich-text {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
        }

        & {
            color: $c--monochrome-dark-gray;
        }
    }

    // Facets
    &.rich-text__gridded {
        background-color: $c--monochrome-pale-gray;

        @include dot-grid();
    }


    // States
}
