// stylelint-disable

&.rich-text-content__underlined-links {
    & {
        a {
            text-decoration: underline;

            @include has-focus(true) {
                text-decoration: underline;
            }
        }
    }
}
