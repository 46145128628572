.main-navigation-dropdown {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 10px 0;
            color: $c--monochrome-dark-gray;
        }

        .main-navigation-dropdown--item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 8px 24px;
            line-height: 1.47;
            font-size: $fz--small;

            @include router-state-active() {
                color: $c--monochrome-light-gray;
                text-decoration: none;
            }

            @include has-focus(true) {
                background-color: $c--monochrome-pale-gray;

                .main-navigation-dropdown--item-icon {
                    color: $c--monochrome-dark-gray;
                }
            }
        }

        .main-navigation-dropdown--item-icon {
            display: flex;
            max-width: 26px;
            margin-right: 25px;
            max-height: 100%;
        }

        .main-navigation-dropdown--item-label {
            white-space: nowrap;
        }
    }

    // Facets
    &.main-navigation-dropdown__secondary {
        & {
            border-top: solid 1px $c--monochrome-silver;
            background-color: $c--monochrome-pale-gray;
            padding-top: 20px;
        }

        .main-navigation-dropdown--item {
            margin: 0 5px;

            @include has-focus(true) {
                color: $c--monochrome-gray;
            }
        }
    }
    // States
}
