.header-animation {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            height: 100vh;
            background-color: $c--brand-primary;

            @include dot-grid();
        }

        .header-animation--claim-wrapper {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }

        .header-animation--shape {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        .header-animation--claim {
            color: $c--monochrome-white;
            max-width: 780px;
            text-align: center;

            @include type--h1;

            @include mq('<=md') {
                font-size: 4rem;
                max-width: 456px;
            }

            @include mq('<=sm') {
                max-width: 343px;
            }
        }

        .header-animation--scroll-indicator {
            pointer-events: all;
            position: absolute;
            bottom: 15px;
            color: $c--monochrome-white;
            cursor: pointer;
        }
    }

    // Facets

    // States
}
