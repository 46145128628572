.info-popover {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;
        }

        .info-popover--toggle {
            color: $c--monochrome-gray;
        }

        .info-popover--inner {
            z-index: z-index('popover');
            max-width: 220px;
            color: $c--monochrome-white;
            background-color: $c--monochrome-gray;
            line-height: 1.47;
            font-size: $fz--small;

            padding: 24px;
        }

        .info-popover--arrow {
            border-color: $c--monochrome-gray;
        }
    }

    // Facets

    // States
}
