.cookie-notification {
    // Vars

    // Support

    // Module
    & {
        & {
            position: fixed;
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: $c--brand-secondary;
            color: $c--monochrome-white;
            padding: 14px 0;
            opacity: 0.8;

            @include mq('<=md') {
                padding: 20px 36px;
            }

            @include mq('<=sm') {
                padding: 20px 24px;
            }
        }

        .cookie-notification--body {
            font-size: $fz--x-small;
            line-height: $lh--x-small;
        }

        .cookie-notification--btn {
            margin-left: 36px;
            padding: 11px 32px;
        }
    }

    // Facets

    // States
}
