/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Media queries and breakpoints
/// See: `util/mq.scss`

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
/// See: `util/z-index.scss`

/// Fonts
/// See: `base/type.scss`

/// Sizes & spacing
/// See: `base/spacing.scss`

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);

//
$def-box-shadow:  0 2px 8px 0 rgba(0, 0, 0, 0.2);
