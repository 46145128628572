.stats-list {
    // Vars

    // Support

    // Module
    & {
        .stats-list--shape {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .stats-list--row.stats-list--row {
            flex-direction: column;
            width: 100%;
            margin: 0;

            @include mq('<=md') {
                flex-direction: column;
            }
        }

        .stats-list--item {
            @include grid--span((ratio: 5/10, mq: '<=md'));
            @include grid--span((ratio: 10/10, mq: '<=sm'));
        }

        .stats-list--label {
            text-align: center;

            @include type--stats-label;
        }

        .stats-list--value {
            text-align: center;

            @include ff--base-bold();
            @include type--h2;

            @include mq('<=md') {
                font-size: 4rem;
            }
        }
    }

    // Facets

    // States
}
