// stylelint-disable selector-max-universal, plugin/selector-bem-pattern

// Have to override the default link styling so that we can underline the links in the richtexts
.rich-text-content.rich-text-content.rich-text-content.rich-text-content {
    // Vars

    // Support

    // Module
    & {
        & {
            h2, h3 {
                color: $c--monochrome-dark-gray;
            }

            a {
                text-decoration: none;
            }

            * {
                + p {
                    margin-top: 24px;
                }

                + h1, + h2, + h3, + h4, + h5, + h6 {
                    margin-top: 44px;
                }

                + ul, + ol {
                    margin-top: 8px;
                }
            }
        }
    }

    // Facets
    @import 'facets/rich-text-content__list-agb';
    @import 'facets/rich-text-content__underlined-links';
}
