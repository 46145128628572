.title-text-buttons {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;

            @include mq('<=md') {
                padding: 60px 0 36px;
            }
        }

        .title-text-buttons--container.title-text-buttons--container {
            overflow: visible;
        }

        .title-text-buttons--title {
            margin-bottom: 8px;
        }

        .title-text-buttons--body {
            margin-bottom: 20px;
        }

        .title-text-buttons--btn {
            @include ff--base-bold();
        }

        .title-text-buttons--row {
            justify-content: space-between;

            @include mq('<=md') {
                justify-content: center;
            }
        }

        .title-text-buttons--content-col {
            padding-top: 16px;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 10/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .title-text-buttons--stats-col {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=md') {
                margin-top: 60px;
                flex-direction: row;
            }

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .title-text-buttons--content-wrapper {
            max-width: 780px;
        }

        .title-text-buttons--btn-wrap {
            margin-right: 16px;
            display: inline-block;

            @include mq('<=sm') {
                display: block;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .title-text-buttons--btn-row {
            @include mq('<=md') {
                flex-direction: column;
            }
        }
    }

    // Facets

    // States
}
