.image-shape {
    // Vars

    // Support

    // Module
    & {
        & {
            overflow: hidden;
        }

        .image-shape--color-filter {
            mix-blend-mode: lighten;
        }
    }

    // Facets

    // States
}
