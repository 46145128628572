.how-to {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background-color: $c--monochrome-pale-gray;

            @include mq('<=md') {
                padding: 60px 0;
            }

            @include dot-grid();
        }

        .how-to--row {
            justify-content: center;
        }

        .how-to--col {
            @include grid--span((ratio: 12/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 4/4, mq: '<=sm'));
        }

        .how-to--title {
            text-align: center;
            margin-bottom: 60px;

            @include mq('<=md') {
                margin-bottom: 36px;
            }
        }

        .how-to--steps.how-to--steps {
            justify-content: center;

            @include mq('<=md') {
                flex-direction: column;
                align-items: center;
            }
        }

        .how-to--step {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));

            @include mq('<=lg') {
                margin-bottom: 60px;
            }
        }

        .how-to--caption {
            text-align: center;
            max-width: 100%;

            @include type--copy();
        }

        .how-to--image {
            margin-bottom: 54px;
            height: 100%;
            max-height: 240px;

            @include mq('<=lg') {
                margin-bottom: 24px;
            }
        }
    }

    // Facets
    &.how-to__large {
        .how-to--step {
            @include grid--span((ratio: 4/12));
        }
    }

    // States
}
