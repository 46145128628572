.trs-simple-fade {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props(1000 $trs--base-fn, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
