.price-table {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 100px 0;

            @include mq('<=md') {
                margin: 60px 0;
            }
        }

        .price-table--container.price-table--container {
            overflow: visible;
        }

        .price-table--title {
            margin-bottom: 44px;
        }

        .price-table--table-wrapper {
            width: 100%;
            margin-bottom: 60px;
        }

        .price-table--footnote {
            margin-top: 8px;

            @include type--caption;

            @include mq('<=md') {
                max-width: 50%;
            }

            @include mq('<=sm') {
                max-width: 100%;
            }
        }

        .price-table--row {
            justify-content: center;
        }

        .price-table--cell-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
