.team-member {
    // Vars

    // Support

    // Module
    & {
        //

        & {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .team-member--image {
            padding: 10px;
            max-width: 372px;
            height: 372px;

            @include mq('<=md') {
                max-width: 336px;
                height: 336px;
            }

            @include mq('<=sm') {
                max-width: 327px;
                height: 327px;
            }
        }

        .team-member--name {
            margin-top: 24px;
            text-align: center;

            @include type--h3;
            @include ff--base-bold();
        }

        .team-member--job-title {
            margin-top: 12px;
        }

        .team-member--link {
            text-align: center;
        }
    }

    // Facets

    // States
}
