.modal {
    // Vars

    // Support

    // Module
    & {
        & {
            pointer-events: none;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }

        .modal--controls {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
        }

        .modal--close-toggle {
            cursor: pointer;
        }

        .modal--row {
            justify-content: center;
        }

        .modal--content {
            pointer-events: all;
            background-color: $c--monochrome-white;
            padding: 50px;
            box-shadow: $def-box-shadow;
        }

        .modal--col {
            position: relative;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 10/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
