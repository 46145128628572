.polygon-tool {
    // Vars

    // Support

    // Module
    & {
        .polygon-tool--frame {
            border: solid 2px $c--monochrome-silver;

            @include mq('<=md') {
                max-height: 400px;
            }

            @include mq('<=sm') {
                max-height: 300px;
            }
        }

        .polygon-tool--button-col {
            align-items: center;
            margin: 60px 0;

            @include mq('<=md') {
                margin: 36px 0 60px;
            }
        }

        .polygon-tool--row {
            justify-content: center;
        }
    }

    // Facets

    // States
}
