/// Global type definitions
///
$fz--large: 2.2rem;
$fz--medium: 1.7rem;
$fz--small: 1.5rem;
$fz--x-small: 1.2rem;

$lh--large: 1.55;
$lh--medium: 1.59;
$lh--small: 1.25;
$lh--x-small: 1.33;

/// Headline definitions
///
// TODO: Define `p` as well as `h1` to `h6` styles
@mixin type--h1 {
    @include ff--base-bold;
    font-size: 6rem;
    line-height: 1.33;

    @include mq('xs') {
        font-size: 4rem;
        line-height: 1.25;
    }
}

@mixin type--h2 {
    @include ff--base-bold;
    font-size: 4rem;
    line-height: 1.25;

    @include mq('<=md') {
        font-size: 3rem;
        line-height: 1.27;
    }
}

@mixin type--h3 {
    @include ff--base-bold;
    font-size: 3rem;
    line-height: 1.27;
}

@mixin type--h4 {
    @include ff--base-regular;
    font-size: 1.8rem;
}

@mixin type--h5 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--h6 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--copy {
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--caption {
    font-size: $fz--x-small;
    line-height: $lh--x-small;
}

@mixin type--subtitle {
    font-size: $fz--large;
    line-height: $lh--large;
}

@mixin type--stats-label {
    font-size: $fz--small;
    line-height: 1.47;
}

@mixin type--link {
    cursor: pointer;
    text-decoration: none;

    @include has-focus(false) {
        color: $c--label-link;
        text-decoration: none;
    }

    @include has-focus(true) {
        color: $c--label-link-hover;
        text-decoration: none;
    }
}

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--base-regular;
    @include type--copy;
    color: $c--label-text;
}

/// Headlines
///
.skel {
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    .caption {
        @include type--caption;
    }

    /// Tags with visual implication
    ///
    b, strong {
        font-weight: $fw--bold;
    }

    i, em {
        font-style: italic;
    }

    sub {
        font-size: $fz--x-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--x-small;
        vertical-align: super;
    }

    /// Simple link
    ///
    a, .u-link {
        color: currentColor;
        text-decoration: none;
        line-height: 1.59;
        transition: all $trs--base;

        &:not(.u-reset):not(.btn) {
            @include type--link;
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        display: block;
        border: 0;
        height: 1px;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
        background-color: currentColor;
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}
