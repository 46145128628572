/// https://gist.github.com/jankorbel/2336513/cb2f68b59521438ac812ad72c1c93d8f656e49ad
///
.dot-loader {
    // Vars
    $num--dots: 3;
    $sz--dot: 8px;
    $trs--dot-loader-fn: linear;
    $trs--dot-loader-speed: 1200ms;
    $trs--dot-loader-delay: 200ms;
    $trs--dot-loader: $trs--dot-loader-speed $trs--dot-loader-fn;

    // Support
    @keyframes ani-dot-loader {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    // Module
    & {
        z-index: 10;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .dot-loader--loader {
            display: flex;
            justify-content: space-between;
            width: 36px;
            height: $sz--dot;
        }

        .dot-loader--dot {
            opacity: 0;
            border-radius: 50%;
            display: block;
            width: $sz--dot;
            height: $sz--dot;

            animation: ani-dot-loader $trs--dot-loader infinite;

            ~ .dot-loader--dot {
                margin-left: 8px;
            }

            @for $i from 1 through $num--dots {
                &.dot-loader--dot:nth-child(#{$i}) {
                    animation-delay: ($i - 1) * $trs--dot-loader-delay;
                }
            }
        }
    }

    // Facets
    & {
        // Base
        &.dot-loader__base {
            background-color: rgba($c--monochrome-white, 0.125);

            .dot-loader--dot {
                background-color: rgba($c--monochrome-white, 0.5);
            }
        }

        // Main placeholder
        &.dot-loader__main {
            background-color: rgba($c--monochrome-white, 0.125);

            .dot-loader--dot {
                background-color: rgba($c--monochrome-black, 0.5);
            }
        }
    }

    // States
    & {
        // Visibility
        & {
            & {
                @include is-visible(false, $trs--base-speed);
            }

            &.dot-loader__is-active {
                @include is-visible(true, $trs--base-speed);
            }
        }
    }
}
