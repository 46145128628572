.contact-info {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background-image: linear-gradient(169deg, $c--gradient-orange, $c--gradient-red 56%, $c--gradient-blue);

            @include stripe-grid();
        }

        .contact-info--row {
            justify-content: center;
        }

        .contact-info--button {
            z-index: 1;
            background: $c--monochrome-white;
            text-transform: uppercase;

            margin-right: 36px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }

            @include ff--base-bold;
        }

        .contact-info--col {
            @include grid--span((ratio: 6/12));
        }
    }

    // Facets

    // States
}
