.tooltip {


    $sz--arrow: 12px;
    $sz--border: 0;

    & {
        & {
            z-index: z-index('popover');
            display: block;
        }

        .tooltip--inner {
            background: $c--monochrome-black;
            color: $c--monochrome-white;
            border-radius: 16px;
            padding: 5px 10px 4px;
        }

        .tooltip--arrow {
            pointer-events: none;
            z-index: z-index('popover');

            position: absolute;
            width: 14px;
            height: 14px;

            &::before, &::after {
                content: '';
                position: absolute;
                //top: 0;
                left: 50%;
                display: block;
                width: $sz--arrow;
                height: $sz--arrow;
                background-color: $c--monochrome-gray;
            }

            &::before {
                transform: translate(-50%, -50%) rotateZ(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotateZ(45deg) translate(-$sz--border, -$sz--border);
            }
        }

        &[x-placement^='top'] {
            margin-bottom: 8px;

            .tooltip--arrow {
                bottom: -$sz--arrow;
                border-width: 5px 5px 0;
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^='bottom'] {
            margin-top: 8px;

            .tooltip--arrow {
                border-width: 0 5px 5px;
                border-left-color: transparent;
                border-right-color: transparent;
                border-top-color: transparent;
                top: 0;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^='right'] {
            margin-left: 5px;

            .tooltip--arrow {
                border-width: 5px 5px 5px 0;
                border-left-color: transparent;
                border-top-color: transparent;
                border-bottom-color: transparent;
                left: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        &[x-placement^='left'] {
            margin-right: 5px;

            .tooltip--arrow {
                border-width: 5px 0 5px 5px;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                right: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 150ms, visibility 150ms;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 150ms;
    }
}
