.main-navigation-content {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            height: 100%;
            width: 100%;

            padding: 0 60px;

            @include mq('<=md') {
                padding: 0 36px;
            }

            @include mq('<=sm') {
                padding: 0 24px;
            }
        }

        .main-navigation-content--logo-wrapper {
            height: 100%;
        }

        .main-navigation-content--logo-link {
            margin-top: 60px;

            @include mq('<=md') {
                margin-top: 36px;
            }

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .main-navigation-content--list {
            width: auto;

            @include mq('<=md') {
                display: none;
            }
        }

        .main-navigation-content--mobile-toggle {
            color: $c--monochrome-white;

            @include mq('>md') {
                display: none;
            }
        }

        .main-navigation-content--item {
            display: inline-block;
            margin: 0 20px;
            //color: $c--monochrome-dark-gray;

            &:last-child {
                margin-right: 0;
            }

            @include type--copy();
        }
    }

    // Facets
    &.main-navigation-content__reduced {
        .main-navigation-content--logo-link {
            margin-top: 24px;
        }
    }

    &.main-navigation-content__primary {
        .main-navigation-content--item {
            @include has-focus(false) {
                color: $c--monochrome-white;
            }

            @include has-focus(true) {
                color: $c--monochrome-dark-gray;
            }
        }

        .main-navigation-content--mobile-toggle {
            color: $c--monochrome-white;
        }
    }

    &.main-navigation-content__secondary {
        .main-navigation-content--item {
            @include has-focus(false) {
                color: $c--monochrome-dark-gray;
            }

            @include has-focus(true) {
                color: $c--brand-primary;
            }
        }

        .main-navigation-content--mobile-toggle {
            color: $c--brand-primary;
        }
    }

    // States
}
