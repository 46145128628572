.shaped-text {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-bottom: 24px;
        }

        .shaped-text--shape {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            height: 110%;
        }

        .shaped-text--text {
            width: 100%;
            padding: 55px 0;
        }
    }

    // Facets

    // States
}
