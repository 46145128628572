// stylelint-disable

&.rich-text-content__list-agb {
    & {
        li {
            font-size: $fz--medium;
            line-height: $lh--medium;
        }

        ol {
            counter-reset: item;
            padding-left: 50px;
            margin-top: 0;

            h1, h2, h3, h4, h5, h6 {
                display: inline-block;
                color: $c--label-text;
                margin-bottom: 8px;

                @include type--h3;
            }

            > li {
                position: relative;
                display: block;
                list-style: initial;

                &::before {
                    content: counters(item, '.') '.';
                    display: initial;
                    counter-increment: item;
                    height: 100%;
                    width: 50px;
                    font-size: initial;
                    color: $c--brand-primary;
                    text-align: left;
                }
            }

            ul {
                padding-left: 26px;
                li {
                    margin-top: 0;
                    &::before {
                        width: 22px;
                        text-align: left;
                    }
                }
            }
        }

        > ol {
            > li {
                margin-bottom: 36px;

                > ol {
                    padding-left: 47px;

                    > li {
                        &::before {
                            width: 47px;
                        }
                    }
                }
            }

            > li::before {
                color: $c--label-text;

                @include type--h3;
            }
        }
    }
}
