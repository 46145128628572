.language-notification {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('language-notification');
            //position: fixed;
            top: 0;
            width: 100%;
            background-color: $c--brand-dark-orange;
            padding: 12px 60px;
        }

        .language-notification--switch {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .language-notification--text {
            font-size: $fz--x-small;
            line-height: $lh--x-small;

            margin-right: 8px;
            color: $c--monochrome-white;
        }

        .language-notification--row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .language-notification--icon {
            color: $c--monochrome-white;
            justify-self: flex-end;
            cursor: pointer;
        }
    }

    // Facets

    // States
}
