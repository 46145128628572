.form {
    // Vars
    $sz--form-fieldset-gutter: (44px 16px);
    $sz--form-field-gutter: (24px 16px);

    $grid--fieldset-items: (row: ('fieldset-row'), cols: ('fieldset'));
    $grid--field-items: (row: ('field-row'), cols: ('field'));

    // Support

    // Module
    & {
        // Fieldset spacing
        & {
            @include grid--root($grid--fieldset-items);
            @include grid--space(map-merge($grid--fieldset-items, (
                gutter: $sz--form-fieldset-gutter,
            )));

            .form--fieldset {
                @include grid--span((ratio: 1/1));
            }
        }

        // Field spacing
        & {
            @include grid--root($grid--field-items);
            @include grid--space(map-merge($grid--field-items, (
                gutter: $sz--form-field-gutter,
            )));

            .form--field-row {
                ~ .form--field-row {
                    padding-top: ($sz--form-field-gutter / 2);
                }
            }

            .form--field {
                @include grid--span((ratio: 1/1));
                align-self: flex-start;
            }
        }
    }

    // Facets

    // States
}
