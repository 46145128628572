.casestudy-content {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 100px 0;

            @include mq('<=md') {
                margin-top: 300px;
            }

            @include mq('<=sm') {
                margin-top: 60px;
            }
        }

        .casestudy-content--container.casestudy-content--container {
            overflow: visible;
        }

        .casestudy-content--lead {
            font-size: $fz--large;
            line-height: $lh--large;
            margin-top: 8px;
            margin-bottom: 44px;
        }

        .casestudy-content--row {
            justify-content: space-between;

            @include mq('<=md') {
                justify-content: center;
            }
        }

        .casestudy-content--side-title {
            color: $c--monochrome-dark-gray;
            margin-bottom: 17px;
            text-align: center;

            @include type--subtitle();
        }

        .casestudy-content--content-col {
            margin-top: 16px;

            @include grid--span((ratio: 7/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .casestudy-content--side-col {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            margin-top: 216px;

            @include mq('<=md') {
                margin-top: 60px;
            }
        }
    }

    // Facets

    // States
}
