.reduced-nav {
    // Vars
    // Support

    // Module
    & {
        & {
            @include grid--root(());
            @include grid--space(());

            @include animated-backgroundcolor-change($c--brand-primary, $c--monochrome-white);

            z-index: z-index('reduced-navigation');
            position: fixed;
            top: -80px;
            box-shadow: $def-box-shadow;

            width: 100%;
            height: 106px;
            transition: all 200ms ease-in-out;

            opacity: 0;
        }

        .reduced-nav--logo {
            justify-self: flex-start;
            max-width: 140px;
            max-height: 62px;
            width: unset;
        }
    }

    // Facets
    &.reduced-nav__mobile-panel {
        & {
            position: initial;
        }
    }

    &.reduced-nav__primary {
        & {
            @include animated-backgroundcolor-active(true);
        }
    }

    &.reduced-nav__secondary {
        & {
            @include animated-backgroundcolor-active(false);
        }
    }

    // States
    &.reduced-nav__on-top:not(.reduced-nav__mobile-panel) {
        display: none;
    }

    &.reduced-nav__not-visible:not(.reduced-nav__mobile-panel) {
        display: none;
    }

    &.reduced-nav__not-on-top:not(.reduced-nav__mobile-panel) {
        display: flex;
    }

    &.reduced-nav__pinned:not(.reduced-nav__mobile-panel) {
        top: 0;
        opacity: 1;
    }

    &.reduced-nav__visible-forced:not(.reduced-nav__mobile-panel) {
        top: 0;
        opacity: 1;
        display: flex;
    }

    &.reduced-nav__unpinned:not(.reduced-nav__mobile-panel) {
        top: -80px;
        opacity: 0;
    }
}
