.generic-form {
    // Vars

    // Support

    // Module
    & {
        .generic-form--fieldset {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // Facets

    // States
}
