/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--bold: 700;

/// The project's font families
///
// TODO: Define font-families
$ff--base: 'Frutiger';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// TODO: Figure out font-stack fallback definitions
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
// stylelint-enable value-keyword-case
