.product-shape {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
        }

        .product-shape--canvas {
            width: 100%;
        }
    }

    // Facets

    // States
}
