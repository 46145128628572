.cta {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;

            background-image: linear-gradient(169deg, $c--gradient-orange, $c--gradient-red 56%, $c--gradient-blue);

            @include stripe-grid();

            @include mq('<=md') {
                padding: 60px 0;
            }
        }

        .cta--row.cta--row {
            justify-content: center;
            align-items: center;

            @include mq('<=sm') {
                display: block;
            }
        }

        .cta--button {
            background: $c--monochrome-white;
            text-transform: uppercase;
            font-size: $fz--medium;
            line-height: 1.18;

            @include ff--base-bold;
        }

        .cta--title {
            color: $c--monochrome-white;
            margin-bottom: 12px,;
        }

        .cta--lead {
            color: $c--monochrome-white;
        }

        .cta--wrapper-col {
            @include grid--span((ratio: 10/12));
        }

        .cta--col {
            @include grid--span((ratio: 5/10));
        }

        .cta--button-col {
            display: flex;
            justify-content: center;

            @include grid--span((ratio: 5/10));
        }

        .cta--title-col {
            @include mq('<=sm') {
                margin-bottom: 40px;
            }
        }
    }

    // Facets

    // States
}
