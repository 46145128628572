/// Medium
///
@mixin btn--size-medium() {
    padding: 16px 32px;
    font-size: $fz--medium;
    line-height: 1.18;
}

/// Small
///
@mixin btn--size-small() {
    padding: 13px 23px;
    font-size: $fz--small;
    line-height: 1.2;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium() {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small() {
    padding: 4px;
}
