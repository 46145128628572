.morphing-shape {
    // Vars

    // Support

    // Module
    & {
        & {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform: translate3d(0, 0, 0);
        }
    }

    // Facets

    // States
}
