.footer {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--brand-secondary;
            padding: 100px 0 44px;
            color: $c--monochrome-white;

            @include mq('<=md') {
                padding: 62px 0 60px;
            }
        }

        .footer--container {
            position: relative;
        }

        .footer--row {
            margin-bottom: 45px;

            @include mq('<=md') {
                margin-bottom: 36px;
            }
        }

        .footer--contact-item {
            position: relative;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .footer--contact-item-icon {
            position: absolute;
            margin-right: 12px;
        }

        .footer--contact-item-icon__location {
            transform: translateY(-2px);
        }

        .footer--contact-link {
            color: $c--monochrome-white;

            @include has-focus(true) {
                color: $c--brand-primary;
            }
        }

        .footer--sub-footer {
            align-items: baseline;
            margin-bottom: 0;
        }

        .footer--claim-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 5/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                justify-content: center;
            }
        }

        .footer--contact-item-text {
            padding-left: 32px;
            font-size: $fz--small;
            line-height: 1.47;
        }

        .footer--claim-text {
            margin-right: 35px;
            line-height: 1.33;

            @include type--caption;
        }

        .footer--claim-image {
            max-height: 44px;
            width: auto;
        }

        .footer--col {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 3/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                margin-bottom: 36px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .footer--col__address {
            @include grid--span((ratio: 4/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('md') {
                margin-bottom: 36px;
            }
        }

        .footer--language-col {
            @include mq('<=sm') {
                margin-top: 0;
                padding-bottom: 34px;
            }

            @include grid--span((ratio: 9/12));
            @include grid--span((ratio: 7/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}
