/// Generic class flag mixin
/// @param {boolean|string} $flag - The flag or a string indicating indifferent state
/// @param {string} $modifier - A bem modifier string
/// @param {string} $root - A bem root string
///
@mixin selector-flag--add-class($flag, $modifier, $root) {
    $root-class: '.#{ $root }';
    $modifier-class: '.#{ $root }#{ $bem-modifier-separator }#{ $modifier }';

    @if ($flag == true) {
        &#{$modifier-class} {
            @content;
        }
    }

    @if ($flag == false) {
        &:not(#{$modifier-class}) {
            @content;
        }
    }

    @if ($flag == default) {
        &#{$root-class} {
            @content;
        }
    }
}

/// Generic pseudo class mixin
/// @param {boolean|string} $flag - The flag or a string indicating indifferent state
/// @param {string} $pseudo-class - A pseudo class selector
///
@mixin selector-flag--add-pseudo-class($flag, $pseudo-class) {
    @if ($flag == true) {
        &#{$pseudo-class} {
            @content;
        }
    }

    @if ($flag == false) {
        &:not(#{$pseudo-class}) {
            @content;
        }
    }

    @if ($flag == default) {
        &:nth-child(1n) {
            @content;
        }
    }
}
