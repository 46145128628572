.quote {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-pale-gray;
            padding: 60px 0;

            @include dot-grid();
        }

        .quote--row {
            justify-content: center;
        }

        .quote--col {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .quote--quote {
            color: $c--monochrome-dark-gray;
            font-weight: 300;
            text-align: center;
            font-size: $fz--large;
            line-height: $lh--large;
            margin-bottom: 16px;
        }

        .quote--source {
            color: $c--brand-secondary;
            font-size: $fz--small;
            text-align: center;
            line-height: 1.47;
        }
    }

    // Facets

    // States
}
