.accordion {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-top: 1px;
        }

        .accordion--item {
            position: relative;
            padding: 12px 16px;
            border-bottom: solid 1px $c--monochrome-light-gray;
            transition: transition-props($trs--base-speed $trs--base-fn, background-color);
        }

        .accordion--dropdown-indicator {
            position: absolute;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            right: 0;
            display: inline-block;
            color: $c--brand-primary;
            transition: transition-props($trs--base-speed $trs--base-fn, transform);
        }

        .accordion--label {
            cursor: pointer;
            position: relative;
        }

        .accordion--content.accordion--content.accordion--content {
            color: $c--monochrome-gray;
            background-color: transparent;
            overflow: hidden;
            margin-top: 12px;
            hyphens: auto;
            transition: transition-props($trs--base-speed $trs--base-fn, height);

            @include type--copy;
        }
    }

    // Facets
    & {
        .accordion--item__open {
            background-color: $c--monochrome-white;
        }

        .accordion--dropdown-indicator__open {
            transform: translate3d(0, -50%, 0) rotateZ(-180deg);
        }
    }

    // States
}
