/// The z-index values of elements
///
$layers: (
    content: 0,
    casestudy-header: 1,
    popover-arrow: 2,
    popover: 3,
    main-navigation: 5,
    reduced-navigation: 6,
    mobile-navigation-panel: 7,
    language-notification: 8,
    overlay-backdrop: 10,
    overlay-modal: 11,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} A z-index number
///
@function z-index($layer: 'content') {
    @if (not map-has-key($layers, $layer)) {
        @error 'The layer `#{$layer}` is not contained within the $layers map!';
    }

    @return map-get($layers, $layer);
}
