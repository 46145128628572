.popover {
    // Vars
    $arrow-size: 10px;
    $popover-offset: -5px;
    $content-padding: 0;
    $background-color: $c--monochrome-white;
    $border-radius: $sz--border-radius-base;


    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .popover--content-wrapper {
            z-index: z-index('popover');
            position: absolute;
            bottom: $popover-offset;
            padding-top: $arrow-size;
            width: 100%;
        }

        .popover--content {
            z-index: 100;
            position: absolute;
            left: 50%;
            padding: $content-padding;
            background-color: $background-color;
            transform: translateX(-50%);
            border-radius: $border-radius;
            box-shadow: $def-box-shadow;
            width: 220px;

            &::before {
                content: '';
                z-index: z-index('popover-arrow');
                position: absolute;
                left: 50%;
                top: -$arrow-size/2;
                width: $arrow-size;
                height: $arrow-size;
                background-color: $background-color;
                box-shadow: -4px -3px 15px 0 rgba(0, 0, 0, 0.1);
                transform: translate3d(-50%, 0, 0) rotateZ(45deg);
            }
        }
    }

    // Facets
    &.popover__popup-icon {
        & {
            display: inline-block;
        }

        .popover--content {
            color: $c--monochrome-white;
            background-color: $c--monochrome-gray;
            padding: 20px;
            width: 220px;

            &::before {
                background-color: $c--monochrome-gray;
            }
        }
    }

    // States
}
