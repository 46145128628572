// stylelint-disable selector-no-qualifying-type
html.scroll-root {
    // Module
    &.scroll-root__is-locked {
        width: 100%;
        height: 100%;
        overflow: hidden;

        > body {
            position: fixed;
            width: 100%;
            height: 100%;
            overflow: visible;
        }
    }
}
