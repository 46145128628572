.picture-element {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
        }

        .picture-element--image {
            width: 100%;
            height: 100%;
        }
    }

    // Facets
    &.picture-element__bw {
        .picture-element--image {
            filter: grayscale(100%);
        }
    }

    &.picture-element__red {
        & {
            position: relative;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.7;
            mix-blend-mode: lighten;
            background-image: linear-gradient(120deg, $c--gradient-orange, $c--gradient-red 59%, $c--gradient-blue);

            @include at-root(html, '.ie11') {
                opacity: 0.3;
            }
        }
    }

    // States
}
