.not-found {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background: $c--monochrome-pale-gray;

            @include dot-grid();
        }

        .not-found--row {
            justify-content: center;
        }

        .not-found--col {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 10/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .not-found--title {
            line-height: $lh--small;
            margin-bottom: 20px;
            text-align: center;
        }

        .not-found--lead {
            max-width: 678px;
            line-height: 1.59;
            font-size: $fz--medium;
            margin-bottom: 60px;
            text-align: center;
        }
    }

    // Facets

    // States
}
