/// Icon helper module with some predefined icon sizes
///
.icon {
    // Vars
    $icon-sizes: node-sass--get-icon-sizes();

    // Support

    // Module
    & {
        display: block;
        width: 100%;
        height: 100%;

        fill: currentColor;
    }

    // Facets
    & {
        // Sizes
        & {
            // Iterate over the `$icon-sizes` map
            @each $key, $value in $icon-sizes {
                &.icon__#{$key} {
                    width: $value;
                    height: $value;
                }
            }
        }
    }

    // States
}
