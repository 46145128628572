.map-stats {
    // Vars

    // Support

    // Module
    & {
        .map-stats--image {
            height: 600px;
            width: 100%;
            background-size: cover;
            background-position: center;

            @include mq('<=md') {
                height: 500px;
            }

            @include mq('<=sm') {
                height: 300px;
            }
        }

        .map-stats--children {
            background: $c--monochrome-pale-gray;

            @include dot-grid();
        }

        .map-stats--container.map-stats--container {
            overflow: visible;
        }

        .map-stats--row.map-stats--row {
            @include mq('<=md') {
                flex-direction: column;
            }
        }

        .map-stats--item {
            @include grid--span((ratio: 4/12));
            //@include grid--span((ratio: 12/12, mq: '<=md'));

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .map-stats--card-row {
            justify-content: center;
            align-items: stretch;
            height: 100%;
        }

        .map-stats--card-col {
            @include grid--span((ratio: 12/12));
            @include grid--span((ratio: 6/12, mq:'<=md'));
            @include grid--span((ratio: 12/12, mq:'<=sm'));
        }

        .map-stats--card {
            height: 100%;
        }

        .map-stats--container {
            position: relative;
            top: -95px;

            @include mq('<=md') {
                top: -52px;
            }
        }
    }

    // Facets

    // States
}
