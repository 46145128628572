.polygon-modal {
    // Vars

    // Support

    // Module
    & {
        .polygon-modal--header {
            text-align: center;
            margin-bottom: 8px;
        }

        .polygon-modal--body {
            text-align: center;
            margin-bottom: 60px;

            @include type--copy;
        }
    }

    // Facets

    // States
}
