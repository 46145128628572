.contact-form {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background-color: $c--monochrome-pale-gray;

            @include dot-grid();
        }

        .contact-form--container.contact-form--container {
            overflow: visible;
        }

        .contact-form--row {
            justify-content: center;
        }

        .contact-form--title {
            margin-bottom: 20px;
            text-align: center;
        }

        .contact-form--body {
            text-align: center;
        }

        .contact-form--title-row {
            margin-bottom: 100px;
        }

        .contact-form--title-col {
            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 12/12, mq:'<=sm'));
        }

        .contact-form--form-col {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 8/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq:'<=sm'));
        }

        .contact-form--success-button {
            margin-top: 60px;
        }
    }

    // Facets
    .contact-form--title-row__success {
        .contact-form--title-col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .contact-form--title,
        .contact-form--body {
            color: $c--brand-primary;
        }
    }

    // States
}
