.product-overview-item {
    // Vars

    // Support

    // Module
    & {
        .product-overview-item--image-wrapper {
            overflow: hidden;
            display: flex;
            justify-content: center;

            @include grid--span((ratio: 2/6));
            @include grid--span((ratio: 4/10, mq: '<=md'));
            @include grid--span((ratio: 6/6, mq: '<=sm'));

            @include mq('<=sm') {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 36px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .product-overview-item--content-wrapper {
            @include grid--span((ratio: 4/6));
            @include grid--span((ratio: 6/10, mq: '<=md'));
            @include grid--span((ratio: 6/6, mq: '<=sm'));
        }

        .product-overview-item--name {
            margin-bottom: 8px;
        }

        .product-overview-item--link {
            //display: inline;
        }

        .product-overview-item--link-icon {
            //§display: inline;
        }
    }

    // Facets

    // States
}
