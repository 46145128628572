@mixin btn--base() {
    border: $sz--border-width-base solid transparent;
    border-radius: $sz--border-radius-base;
    transition: transition-props($trs--base, color, border-color, background-color, opacity, transform, background);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;

    @include ff--base-bold();
}
