/// Field styles
///
@mixin form-field--primary-textarea() {
    // Vars
    $sz--min-height: 50px;

    // Module
    & {
        .form-field--input {
            max-width: 100%;
            padding-bottom: 24px;
            min-height: $sz--min-height;
            resize: vertical;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-input-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-textarea-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-input-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary-input-error();
        }
    }
}

/// Focus
///
@mixin form-field--primary-textarea-focus() {
    .form-field--title-label {
        color: $c--brand-primary;
    }

    .form-field--input {
        border-bottom-width: 1px;
        border-bottom-color: $c--brand-primary;
    }
}
