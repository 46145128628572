.client-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 100px 0;
            background-color: $c--monochrome-pale-gray;

            @include mq('<=md') {
                padding: 60px 0;
            }

            @include dot-grid();
        }

        .client-list--row {
            justify-content: center;
        }

        .client-list--title-row {
            margin-bottom: 30px;
        }

        .client-list--icon-row {
            margin-top: 30px;
        }

        .client-list--title-col {
            align-items: center;
            justify-content: center;

            @include grid--span((ratio: 8/12));
            @include grid--span((ratio: 10/12, mq:'<=md'));
            @include grid--span((ratio: 4/4, mq:'<=sm'));
        }

        .client-list--list-item {
            display: flex;
            justify-content: center;

            @include grid--span((ratio: 2/12));
            @include grid--span((ratio: 5/12, mq: '<=md'));
            @include grid--span((ratio: 2/4, mq:'<=sm'));

            @include mq('<=md') {
                margin: 10px 0;
            }

            @include mq('<=sm') {
                &:nth-child(odd) {
                    justify-content: flex-start;
                }

                &:nth-child(even) {
                    justify-content: flex-end;
                }
            }
        }

        .client-list--title {
            text-align: center;
            margin-bottom: 24px;

            @include ff--base-bold();
        }

        .client-list--lead {
            text-align: center;
        }
    }

    // Facets

    // States
}
