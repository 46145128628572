.main-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            @include grid--root(());
            @include grid--space(());

            z-index: z-index('main-navigation');

            position: absolute;
            top: -1px;

            width: 100%;
            height: 168px;

            @include mq('<=sm') {
                height: 112px;
            }
        }

        .main-nav--logo {
            justify-self: flex-start;
            max-width: 140px;
            max-height: 72px;

            @include mq('<=xs') {
                max-width: 120px;
            }
        }
    }

    // Facets

    &.main-nav__primary {
        & {
            background-color: transparent;
        }
    }

    &.main-nav__secondary {
        & {
            position: initial;
            background-color: $c--monochrome-white;
        }
    }

    &.main-nav__mobile-open {
        & {
            position: initial;
        }
    }

    &.main-nav__mobile-open.main-nav__primary {
        & {
            background: $c--brand-primary;
        }
    }

    // States
}
