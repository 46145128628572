.image-text-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 50px 0;

            @include mq('<=sm') {
                padding: 30px 0;
            }
        }

        .image-text-list--row {
            justify-content: center;
        }

        .image-text-list--item {
            padding: 50px 0;

            @include mq('<=sm') {
                padding: 30px 0;
            }
        }

        .image-text-list--col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    // Facets
    &.image-text-list__dark {
        & {
            background-color: $c--monochrome-pale-gray;

            @include dot-grid();
        }
    }

    // States
}
