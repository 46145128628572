@mixin dot-grid(
    $grid-size: 40px,
    $grid-offset: -5px,
    $grid-color: rgba(8, 9, 11, 0.2),
    $grid-dot-gradiant-size: 5%
) {

    position: relative;

    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: transparent;
        background-image: radial-gradient($grid-color $grid-dot-gradiant-size, transparent $grid-dot-gradiant-size);
        background-size: $grid-size $grid-size;
        background-position: $grid-offset $grid-offset;
    }
}

@mixin stripe-grid(
    $grid-size-x: 24px,
    $grid-size-y: 24px,
    $grid-offset: 20px,
    $grid-color: rgba(8, 9, 11, 0.07)
) {

    position: relative;

    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(-45deg, transparent 5px, $grid-color 8px, transparent 0);
        background-color: transparent;
        background-size: $grid-size-x $grid-size-y;
        background-position: $grid-offset $grid-offset;
    }
}
