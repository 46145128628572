.language-switch {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;
        }

        .language-switch--icon {
            margin-left: 13px;
            display: inline-block;
        }

        .language-switch--item {
            font-size: $fz--small;
            line-height: 1.47;
            display: inline-block;
            text-transform: uppercase;
            margin-right: 8px;
            color: $c--monochrome-white;
            text-decoration: underline;

            @include router-state-active {
                color: $c--brand-primary;
                text-decoration: none;
            }

            @include has-focus(true) {
                color: $c--brand-primary;
            }
        }
    }

    // Facets
    &.language-switch__notification {
        .language-switch--item {
            font-size: $fz--x-small;
            line-height: $lh--x-small;

            @include has-focus(true) {
                color: $c--monochrome-dark-gray;
            }

            @include router-state-active {
                color: $c--monochrome-dark-gray;
            }
        }
    }

    & {
        .language-switch--item__inactive {
            color: $c--monochrome-white;
        }
    }

    // States
}
