.replay-target {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
            height: 100%;
        }

        .replay-target--shape-wrapper {
            width: 100%;
            height: 100%;

            @include mq('<=xs') {
                transform: scale(0.4);
            }

            @include mq('sm') {
                transform: scale(0.6);
            }

            @include mq('md') {
                transform: scale(0.8);
            }
        }

        .replay-target--rotation-wrapper {
            width: 100%;
            height: 100%;
        }

        .replay-target--shape {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center center;
            transform: translate3d(-50%, -50%, 0) scale(2);
        }
    }

    // Facets

    // States
}
