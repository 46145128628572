.product-overview {
    // Vars

    // Support

    // Module
    & {
        & {
            @include dot-grid();

            padding: 100px 0 50px;
            background-color: $c--monochrome-pale-gray;

            @include mq('<=md') {
                padding: 60px 0 30px;
            }
        }

        .product-overview--title {
            text-align: center;
            margin: 0 0 24px;

            @include mq('<=sm') {
                margin: 0 0 24px;
            }
        }

        .product-overview--lead {
            text-align: center;
        }

        .product-overview--header-row,
        .product-overview--button-row {
            justify-content: center;
        }

        .product-overview--header-row {
            margin-bottom: 50px;

            @include mq('<=sm') {
                margin-bottom: 25px;
            }
        }

        .product-overview--button-row {
            @include mq('<=sm') {
                margin-top: 25px;
            }
        }

        .product-overview--header-col {
            @include grid--span((ratio: 8/12, mq: '>sm'));
            max-width: 780px;

            @include mq('<=sm') {
                max-width: 100%;
            }
        }

        .product-overview--product-list {
            @include mq('<=sm') {
                margin: 0 0 25px;
            }
        }

        .product-overview--product-list-item {
            @include grid--span((ratio: 6/12, mq: '>md'));
            @include grid--span((ratio: 12/12, mq: '<=lg'));

            margin: 50px 0;

            @include mq('<=md') {
                margin: 30px 0;
            }

            @include mq('<=sm') {
                padding: 0;
            }
        }
    }

    // Facets

    // States
}
