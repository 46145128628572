.overlay {
    // Vars

    // Support

    // Module
    & {
        .overlay--backdrop {
            z-index: z-index('overlay-backdrop');
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $c--monochrome-black;
            opacity: 0.5;
        }

        .overlay--modal {
            z-index: z-index('overlay-modal');
        }
    }

    // Facets

    // States
}
