.image-text-item {
    // Vars

    // Support

    // Module
    & {
        .image-text-item--row.image-text-item--row {
            justify-content: center;
            align-items: flex-start;

            @include mq('<=sm') {
                flex-direction: row-reverse;
            }
        }

        .image-text-item--title {
            margin-bottom: 8px;

            @include mq('<=sm') {
                margin-bottom: 16px;
            }
        }

        .image-text-item--col {
            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 7/12, mq :'<=md'));
            @include grid--span((ratio: 12/12, mq :'<=sm'));
        }

        .image-text-item--image-col {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 5/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            @include mq('<=sm') {
                margin-bottom: 20px;
            }
        }

        .image-text-item--image {
            width: 100%;
            height: auto;
        }


        .image-text-item--lead {
            ul {
                line-height: 1.8;
            }
        }

        .image-text-item--link {
            margin-top: 8px;

            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }

            @include mq('<=lg') {
                margin-left: 0;
            }
        }

        .image-text-item--button {
            margin-right: 16px;
            text-transform: uppercase;

            &:first-child {
                margin-top: 20px;
            }

            @include mq('<=lg') {
                margin-top: 8px;
            }
        }
    }

    // Facets
    &.image-text-item__image-centered {
        .image-text-item--row {
            align-items: center;
        }
    }

    &.image-text-item__reversed {
        .image-text-item--row {
            flex-direction: row-reverse;
        }
    }

    // States
}
