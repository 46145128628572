.rotating-svg {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
