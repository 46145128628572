.title-list {
    // Vars

    // Support

    // Module
    & {
        & {
            width: 100%;
        }

        .title-list--title {
            margin-bottom: 20px;
        }

        .title-list--list {
            width: 100%;
            column-count: 2;
            column-gap: 36px;
            padding: 0 0 20px;

            @include mq('<=md') {
                column-count: 1;
            }
        }

        .title-list--list-item {
            position: relative;
            top: 0;
            padding-top: 25px;
            padding-bottom: 25px;
            padding-left: 66px;
            list-style-position: inside;
            vertical-align: top;
            break-inside: avoid-column;
            page-break-inside: avoid;


            &::before {
                content: '';
                border-radius: 50px;
                position: absolute;
                left: 0;
                top: 50%;
                width: 40px;
                height: 40px;
                transform: translateY(-50%);

                color: $c--monochrome-white;
            }

            &::after {
                content: '';
                border-radius: 50px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                padding-top: 7px;
                width: 40px;
                height: 40px;
                color: $c--monochrome-white;
                text-align: center;
                font-size: $fz--medium;
                line-height: $lh--medium;

                background: no-repeat center;

                @include ff--base-bold();
                @include type--copy();
            }
        }

        .title-list--list-content {
            max-width: 444px;
        }
    }

    // Facets
    &.title-list__ordered {
        .title-list--list-item {
            counter-increment: ordered-list-counter;

            &::before {
                background: linear-gradient(132deg, $c--gradient-orange, $c--gradient-red 59%, $c--gradient-blue);
            }

            &::after {
                content: counter(ordered-list-counter);
            }
        }
    }

    &.title-list__unordered {
        .title-list--list-item {
            &::before {
                background: linear-gradient(290deg, $c--gradient-orange, $c--gradient-red 90%, $c--gradient-blue);
            }

            &::after {
                background-image: url('#{$web-static}/img/ic_16_checkmark.svg');
            }
        }
    }

    // States
}
