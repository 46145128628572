/// Field styles
///
@mixin form-field--primary-checkbox() {
    // Vars
    $sz--checkbox-icon: 16px;
    $sz--checkbox-border-radius: $sz--border-radius-base;

    // Module
    & {
        .form-field--input {
            ~ .form-field--label-wrap {
                .form-field--box {
                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--checkbox-border-radius;
                    }
                }

                .form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: ($sz--checkbox-icon / -2);
                    margin-top: ($sz--checkbox-icon / -2);
                    width: $sz--checkbox-icon;
                    height: $sz--checkbox-icon;
                }
            }
        }
    }

    // States
    & {
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-checkbox__focus();
        }
    }
}

@mixin form-field--primary-checkbox__focus() {
    .form-field--input {
        ~ .form-field--label-wrap {
            .form-field--box {
                &,
                &::after,
                &::before {
                    border-color: $c--brand-light-orange;
                }
            }
        }
    }
}
