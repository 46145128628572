.stats-card {
    // Vars

    // Support

    // Module
    & {
        & {
            box-shadow: $def-box-shadow;
            padding: 40px 40px 45px;
            background-color: $c--monochrome-white;

            @include mq('<=md') {
                padding: 24px;
            }
        }

        .stats-card--title {
            margin-bottom: 8px;
        }

        .stats-card--body {
            line-height: 1.59;
        }

        .stats-card--link {
            margin-top: 4px;
        }
    }

    // Facets

    // States
}
