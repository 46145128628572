.logo-list {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-pale-gray;

            @include dot-grid();
        }

        .logo-list--list {
            width: 100%;
        }

        .logo-list--button {
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
    }

    // Facets

    // States
}
