.static-image {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        height: auto;
    }

    // Facets

    // States
}
